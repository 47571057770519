import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styles from "./PasswordForgetForm.module.css"

class PasswordForgetForm extends Component {

    state = {
        email: '',
        error: null,
        submited: false,
    }

    onSubmit = event => {
        const { email } = this.state;

        this.props.firebase
            .doPasswordReset(email)
            .then(() => {
                this.setState({ 
                    email: '',
                    error: null,
                    submited: true
                });
            })
            .catch(error => {
                this.setState({ error: error });
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { email, error } = this.state;

        const isInvalid = email === '';

        return (
            <div className={styles.PasswordForgetForm}>

                {!this.state.submited? (
                    <form onSubmit={event => this.onSubmit(event)} className={styles.Form}>
                        <h2>Password Forget</h2>
                        <div className={styles.InputGroup}>
                            <input
                                name="email"
                                value={email}
                                onChange={this.onChange}
                                type="text"
                                placeholder="Email"
                            />
                            {error && <p className={styles.error}>{error.message}</p>}
                        </div>
                        <button disabled={isInvalid} type="submit" className={styles.Button+' '+styles.ripple}>
                            Reset my password
                        </button>
                    </form>
                ):(
                    <form className={styles.Form}>
                        <h2>Check your email to reset your password</h2>
                        <Link to={'/signin'}>
                            <button className={styles.Button+' '+styles.ripple}>
                                SIGN IN
                            </button>
                        </Link>
                    </form>
                )}
        </div>
        );
    }
}

const mapStateToProps = state =>{
    return {
        session: state.firebase.session,
        firebase: state.firebase.api
    }
};

export default compose(
    connect(mapStateToProps),
    withRouter
)(PasswordForgetForm)
