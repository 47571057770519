import * as actionTypes from "../actions/actionTypes";
import objectUpdater from "../../helpers/ObjectUpdater"

const initialState = {
    isByeSaid : false,
    whoSaidBye : "nobody"
};

const sayBye = ( state, action ) => {
    const updatedState = {
        isByeSaid: true,
        whoSaidBye: action.byeMan
    };
    return objectUpdater( state, updatedState );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SAY_BYE: return sayBye(state, action);
        // case actionTypes.SAY_MISC: return sayMisc(state, action);
        default: return state;
    }
};

export default reducer;
