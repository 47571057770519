import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styles from "./FilmForm.module.css";

import FilmEntity from "entities/FilmEntity";

class FilmForm extends Component{

    state = {
        title: '',
        diffusion: '',
        minutage: '',
        day: '5',
        coverUrl: '',
        filmId: '',
        file: null,
        fileInit: false,
        error: null,
    }

    componentDidMount(){
        if(this.props.datas && this.state.title === ''){
            this.setState({
                title: this.props.datas.title,
                diffusion: this.props.datas.diffusion,
                minutage: this.props.datas.minutage,
                day: this.props.datas.day,
                coverUrl: this.props.datas.coverUrl,
                filmId: this.props.datas.filmId,
            })
        }
    }


    onSubmit = event => {
        event.preventDefault();
        let uid = Math.round(Math.random()*1000000000000)
        let path = '';
        if(this.props.createMode){
            path = '/images/'+uid
        }else{
            path = '/images/'+this.state.filmId
        }
        if(this.state.file){
            this.props.firebase.storeFile(this.state.file, path, upload => {
                if(this.props.createMode){
                    this.props.firebase.getStoredImageUrl(uid.toString(), url => {
                    FilmEntity.create(this.props.firebase, {
                       title: this.state.title, 
                       diffusion: parseInt(this.state.diffusion),
                       minutage: parseInt(this.state.minutage),  
                       day: parseInt(this.state.day),
                       coverUrl: url
                   })}
                )}else{
                    this.props.firebase.getStoredImageUrl(this.state.filmId, url => {
                            FilmEntity.update(this.props.firebase, this.state.filmId, {
                                title: this.state.title, 
                                diffusion: parseInt(this.state.diffusion),
                                minutage: parseInt(this.state.minutage),   
                                day: parseInt(this.state.day),
                                coverUrl: url
                            })
                            console.log('EVERYTHING IS OK')            
                    });
                }
            })
        }else{
            FilmEntity.update(this.props.firebase, this.state.filmId, {
                title: this.state.title, 
                diffusion: parseInt(this.state.diffusion),
                minutage: parseInt(this.state.minutage),   
                day: parseInt(this.state.day),
                coverUrl: this.state.coverUrl
            })
        }
        
    }

    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    setUpImage = event => {
        this.setState({
            file : event.target.files[0],
            fileInit: true
        })
    }

    deleteFilm = event => {
        event.preventDefault();
        FilmEntity.delete(this.props.firebase, this.state.filmId);
    }

    render(){
        const { title, diffusion, minutage, fileInit, day, error } = this.state;
        let isInvalid;
        if(this.props.createMode){
            isInvalid = title === '' || diffusion === '' || minutage === '' || day === '' || fileInit === false ;
        }else{
            isInvalid = title === '' || diffusion === '' || day === '';
        }
        
        const style = !this.props.createMode ? styles.FilmForm : styles.FilmFormCreate

        return(
            <div className={style}>
                {!this.props.createMode && 
                    this.props.children
                }
                <form onSubmit={this.onSubmit} className={styles.Form}>
                    <div className={styles.InputGroup}>
                        <label>
                            Titre du film
                            <input
                                name="title"
                                value={title || ''}
                                onChange={this.onChange}
                                type="text"
                                placeholder="Titre du film"
                            />
                        </label>
                        <label className={styles.Hour}>
                            Horaire de diffusion
                            <div className={styles.HourGroup}>
                            <input
                                name="diffusion"
                                value={diffusion || ''}
                                onChange={this.onChange}
                                type="text"
                                placeholder="00"
                            /><strong>:</strong>
                            <input
                                name="minutage"
                                value={minutage || ''}
                                onChange={this.onChange}
                                type="text"
                                placeholder="00"
                            />
                            </div>
                            
                        </label>
                        <label>
                            Jour de diffusion
                            <select name="day" id="day-select" value={day || ''} onChange={this.onChange}>
                                <option value="5">05/08</option>
                                <option value="6">06/08</option>
                                <option value="7">07/08</option>
                                <option value="8">08/08</option>
                            </select>
                    
                        </label>
                        <label className={styles.InputFileLabel}>
                            <input
                                id="file-upload"
                                name="image"
                                onChange={this.setUpImage}
                                type="file"
                            />
                            {!this.props.createMode ? "Changer l'affiche du film" : "Choisir l'image du film"}
                        </label>
                        {!this.props.editMode ? null : <span className={styles.QuitEdit} onClick={() => this.quitEditMode()}>Quitter le mode édition</span>}
                        {error && <p className={styles.error}>{error.message}</p>}
                    </div>
                    <div className={styles.BtnGroup}>
                        {!this.props.createMode &&
                            <button className={styles.Button+' '+styles.ripple} onClick={this.deleteFilm}>
                                Supprimer
                            </button>
                        }
                        <button disabled={isInvalid} type="submit" className={styles.Button+' '+styles.ripple}>
                            Enregistrer
                        </button>
                    </div>
                </form>
            </div>
        );
    }

}

const mapStateToProps = state =>{
    return {
        session: state.firebase.session,
        firebase: state.firebase.api
    }
};

export default compose(
    connect(mapStateToProps),
    withRouter
)(FilmForm)
