import React from "react";
import styles from "./DashboardBar.module.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import \* as compActions from "../../store/actions/actions";

const DashboardBar = (props) => {

    return(
        <div className={styles.Container}>
            <span className={styles.Selector} onClick={() => props.onChangeOption('INSCRITS')}>Inscrits</span>
            <span className={styles.Selector} onClick={() => props.onChangeOption('PRESENTATION')}>Présentation</span>
            <span className={styles.Selector} onClick={() => props.onChangeOption('ACTUS')}>Actus</span>
            <span className={styles.Selector} onClick={() => props.onChangeOption('PROGRAMME')}>Programme</span>
        </div>
    );

}

const mapStateToProps = state => {
    return {
        session: state.firebase.session,
        firebase: state.firebase.api
    }
}
 
 export default connect(mapStateToProps)(withRouter(DashboardBar));
