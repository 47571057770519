/////////////////////////////
/** FIREBASE CONFIGURATION */
/////////////////////////////

//SDK configuration from firebase console
import config from 'reaxiomConfig.js';

//Firebase SDK config
export const sdkConfig = config.sdkConfig.firebase;
//Sign-In redirection default route
export const signInRedirect = config.routes.signin;
//Url of redirection for signup email confirmation
export const emailConfirmationRedirectUrl = config.routes.emailConfirmationRedirectUrl;
//Password Forget route
export const passwordForgetRoute = config.routes.passwordForget;
//Firebase language setup
export const language = config.misc.language;
//Roles and Auth
export const roles = config.authorization.roles;
export const access = config.authorization.access;

export default sdkConfig;

