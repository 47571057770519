import * as actionTypes from "../actions/actionTypes";
import objectUpdater from "../../helpers/ObjectUpdater"

const initialState = {
    isModalOn : false,
    selectSubDates : {
        day1 : false,
        day2 : false,
        day3 : false,
        day4 : false,
    }
};

const toggleModal = ( state, action ) => {
    const updatedState = {
        isModalOn: !state.isModalOn
    };
    return objectUpdater( state, updatedState );
};

const selectSubDates = ( state, action ) => {
    const updatedState = {
        selectSubDates: action.dates
    };
    return objectUpdater( state, updatedState );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.TOGGLE_MODAL: return toggleModal(state, action);
        case actionTypes.SELECT_SUB_DATES: return selectSubDates(state, action);
        default: return state;
    }
};

export default reducer;
