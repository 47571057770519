import React from "react";
import styles from "./NavTabs.module.css";
import { withRouter } from "react-router-dom";

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


const navTabs = (props) => {

    const style = {
        tabsContainers: {
            background: 'linear-gradient(45deg, #FE6B8B70 30%, #FF8E5370 90%)',
            color: 'white',
            width: '100%',
        },
        tabs:{
            borderColor: 'grey'
        },
        tab:{
            color: 'white',
            fontFamily: 'Roboto',
            fontSize: "20px",
            textTransform:'none',
            fontWeight: 'bold',
        }
    };


    const pageChange = (btnType) => {
        switch(btnType){
            case "ACTU" : props.history.push('/');
            break;
            case "PROG" : props.history.push('/films');
            break;
            default: return;
        }
    }

    return(
        <div className={styles.Container}>
            <Paper square style={style.tabsContainers}>
                    <Tabs value={props.history.location.pathname === "/" ? 'ACTUS_TAB' : 'FILMS_TAB'} 
                    centered 
                    variant="fullWidth">
                        <Tab onClick={() => pageChange('ACTU')} value='ACTUS_TAB' label="ACTUS" style={style.tab}/>
                        <Tab onClick={() => pageChange('PROG')} value='FILMS_TAB' label="PROGRAMME" style={style.tab}/>
                    </Tabs>
            </Paper>
        </div>
    );

}

export default withRouter(navTabs);
