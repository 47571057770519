import * as actionTypes from "../actions/actionTypes";
import objectUpdater from "../../helpers/ObjectUpdater"

const initialState = {
    isHelloSaid : false,
    whoSaidHello : "nobody"
};

const sayHello = ( state, action ) => {
    const updatedState = {
        isHelloSaid: true,
        whoSaidHello: action.helloMan
    };
    return objectUpdater( state, updatedState );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SAY_HELLO: return sayHello(state, action);
        // case actionTypes.SAY_MISC: return sayMisc(state, action);
        default: return state;
    }
};

export default reducer;
