import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styles from "./PresentationForm.module.css";

import PresentationEntity from "entities/PresentationEntity";

class PresentationForm extends Component{

    state = {
        content: '',
        error: null,
        updated: false,
    }

    componentDidMount(){
        PresentationEntity.getOne(this.props.firebase, 'presentation', this.setContent)
    }

    setContent = (data) => {
        this.setState({
            content: data.content
        })
    }

    onSubmit = event => {
        PresentationEntity.update(this.props.firebase, 'presentation', {content: this.state.content})
        this.setState({
            updated: true
        })
        event.preventDefault();
    }

    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    render(){
        const { content, error, updated } = this.state;
        const isInvalid = content ==='';

        return(
            <div className={styles.PostForm}>
                <form onSubmit={event => this.onSubmit(event)} className={styles.Form}>
                    <h2>Modifier le texte de présentation</h2>
                    <div className={styles.InputGroup}>
                        <textarea
                            name="content"
                            value={content || ''}
                            onChange={this.onChange}
                            placeholder="Contenu"
                        />
                        {error && <p className={styles.error}>{error.message}</p>}
                        {updated && <p className={styles.success}>Présentation mise à jour !</p>}
                    </div>
                    <button disabled={isInvalid} type="submit" className={styles.Button+' '+styles.ripple}>
                        Enregistrer
                    </button>
                </form>
            </div>
        );
    }

}

const mapStateToProps = state =>{
    return {
        session: state.firebase.session,
        firebase: state.firebase.api
    }
};

export default compose(
    connect(mapStateToProps),
    withRouter
)(PresentationForm)
