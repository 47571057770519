import React from "react";
import styles from "./DateSelector.module.css";
import { connect } from "react-redux";
import * as dateSelectorActions from "../../store/actions/actions";

const dateSelector = (props) => {


	const changeSelectedDate = (date) => {
		props.onSelectDate(date);
	}

    return(
        <div className={styles.Container}>
			<div className={styles.Selector}>
				<button onClick={() => changeSelectedDate(5)}
				className={props.selectedDate === 5 ? styles.Selected : null}>05/08</button>
				<button onClick={() => changeSelectedDate(6)}
				className={props.selectedDate === 6 ? styles.Selected : null}>06/08</button>
				<button onClick={() => changeSelectedDate(7)}
				className={props.selectedDate === 7 ? styles.Selected : null}>07/08</button>
				<button onClick={() => changeSelectedDate(8)}
				className={props.selectedDate === 8 ? styles.Selected : null}>08/08</button>
			</div>
        </div>
    );

}

const mapStateToProps = state =>{
 	return {
 		selectedDate: state.dateSelector.selectedDate,
 	}
 };
 
 const mapDispatchToProps = dispatch =>{
 	return {
 		onSelectDate: (date) => dispatch(dateSelectorActions.selectDate(date))
 	}
 };
 
 export default connect(mapStateToProps, mapDispatchToProps)(dateSelector);
