import React from 'react';
import Film from '../Film';
import styles from './FilmList.module.css';
import FilmForm from '../FilmForm/FilmForm';

const FilmList = (props) => {

    const list = props.adminMode ? (
        <div className={styles.ContainerAdmin} >
            {props.datas && props.datas.map(data => 
                <FilmForm datas={data} key={Math.random()}>
                    <Film key={Math.random()} datas={data} adminMode={props.adminMode} toggleEditMode={props.toggleEditMode}/>
                </FilmForm>                
            )}
        </div>
    ):(
        <div className={styles.Container} >
            {props.datas && props.datas.map(data => {
                if(data.day === props.selectedDate){
                    return <Film key={Math.random()} datas={data} adminMode={props.adminMode} toggleEditMode={props.toggleEditMode}/>
                }else{
                    return null;
                }
            }
            )}
        </div>
    );

    return list

}



export default FilmList;