import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./EmailVerify.module.css";


class EmailVerify extends Component {

    state = {
        resend: false
    }

    onSendEmailVerification = () => {
        this.props.firebase
            .doSendEmailVerification()
            .then(() => this.setState({ isSent: true }));
    }
    
    render() {
        return (
            <div className={styles.Container}>
                <h2>Access denied</h2>
                {this.state.isSent ? (
                    <p><strong>E-Mail confirmation sent:</strong> Check you E-Mails (Spam
                    folder included) for a confirmation E-Mail.
                    Refresh this page once you confirmed your E-Mail.</p>
                ):(
                    <p><strong>Verify your E-Mail: </strong> Check you E-Mails (Spam folder
                    included) for a confirmation E-Mail or send
                    another confirmation E-Mail.</p>
                )}
                
                <button className={styles.Button+' '+styles.ripple} type="button" onClick={this.onSendEmailVerification} disabled={this.state.isSent}>
                Send another confirmation E-Mail
                </button>
            </div> 
        );
    }

}

const mapStateToProps = state =>{
    return {
        session: state.firebase.session,
        firebase: state.firebase.api
    }
};
  
export default connect(mapStateToProps)(EmailVerify);