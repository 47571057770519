import React, { Component } from "react";
import { PasswordForgetForm } from "reaxiom/firebase"
import styles from "./PasswordForget.module.css";

class PasswordForget extends Component{

    render(){
        return(
            <div className={styles.Container}>
                <PasswordForgetForm/>
            </div>
        );
    }

}

export default PasswordForget;