import React, {Component} from "react";
import styles from "./NewsFeed.module.css";
import { connect } from "react-redux";
 //import \* as compActions from "../../store/actions/actions";

import HeroBanner from "../../../components/HeroBanner/HeroBanner";
import SectionHeader from "../../../components/SectionHeader/SectionHeader";
import PostList from '../../../components/Post/PostList/PostList';
import PostEntity from 'entities/PostEntity';
import PresentationEntity from 'entities/PresentationEntity';
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Presentation from "components/Presentation/Presentation";


class NewsFeed extends Component {

    state = {
        postsLoading: true,
        presentationLoading: true,
        posts: null,
        presentation: null,
        imageUrl: '' 
    }

    componentDidMount(){
        PostEntity.getAll(this.props.firebase, this.setPosts);
        PresentationEntity.getOne(this.props.firebase, 'presentation', this.setPresentation)
        this.props.firebase.getStoredImageUrl('Banner.png', this.setImageUrl);
    }

    setPosts = (posts) => {
        this.setState({
            posts: posts,
            postsLoading: false
        })
    }

    setPresentation = (presentation) => {
        this.setState({
            presentation: presentation,
            presentationLoading: false
        })
    }

    setImageUrl = (url) => {
        this.setState({
            imageUrl: url 
        })
    }

    upImage = event => {
        let path = '/images/testdimage.png'
        this.props.firebase.storeFile(this.state.file, path);
        event.preventDefault();
    }

    setUpImage = event => {
        this.setState({
            file : event.target.files[0]
        })
    }

    render(){

        const content = this.state.postsLoading ? 
            <LoadingSpinner/> :
            this.state.posts ? 
                <PostList datas={this.state.posts}/> : 
                <h2>There's no post</h2>

        const presentation = this.state.presentationLoading ? 
            <LoadingSpinner/> :
            <Presentation datas={this.state.presentation}/>

        return(
            <div className={styles.Container}>
                <HeroBanner/>
                    {/* <img alt='lol' src={this.state.imageUrl}/> */}
                    
                <SectionHeader>PRESENTATION</SectionHeader>
                {presentation}
                <SectionHeader>ACTUALITES</SectionHeader>
                {content}
            </div>
        );
    }
}

const mapStateToProps = state =>{
    return {
        session: state.firebase.session,
        firebase: state.firebase.api
    }
};
 
 const mapDispatchToProps = dispatch =>{
 	return {
 		// onAction: (val) => dispatch(compActions.action(val))
 	}
 };
 
 export default connect(mapStateToProps, mapDispatchToProps)(NewsFeed);
