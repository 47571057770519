import React from 'react';
import styles from './Post.module.css';
import {connect} from 'react-redux';
import PostEntity from "entities/PostEntity";


const Post = (props) => {

    const post = props.adminMode ? 
        (
        <div className={styles.ContainerAdmin} key={props.datas.postId}>
            <div className={styles.PostHeader}>
                <span className={styles.Title}>{props.datas.title} </span>
                <div className={styles.AdminOptions}>
                            <span className={styles.Update} onClick={(postId) => props.toggleEditMode(props.datas.postId)}>Modifier</span>
                    {!props.datas.main &&
                            <span className={styles.Delete} onClick={() => PostEntity.delete(props.firebase, props.datas.postId)}>Supprimer</span>
                    }
                </div>
            </div>
            <div className={styles.Content}>
                <div style={{
                    width: '30%',
                    height: '300px', 
                    backgroundImage: 'url('+props.datas.coverUrl+')',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'}}/>
                    <p className={styles.NewsContent} dangerouslySetInnerHTML={{ __html: props.datas.content.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p>
            </div>
        </div>
        ) :
        (
        <div className={styles.Container} key={props.datas.postId}>
            <div className={styles.PostHeader}>
                <h2 className={styles.NewsTitle}>{props.datas.title}</h2>
            </div>
            <div className={styles.Content}>
                {props.datas.coverUrl &&
                <div className={styles.PostImage} 
                    style={{
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: 'url('+props.datas.coverUrl+')',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain'}}/>
                }
                <p className={styles.NewsContent} dangerouslySetInnerHTML={{ __html: props.datas.content.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p>
            </div>
        </div>
        );


    return post;
}

const mapStateToProps = state => {
    return {
        session: state.firebase.session,
        firebase: state.firebase.api
    }
}

export default connect(mapStateToProps)(Post);