import React, { Component } from "react";
import { connect } from 'react-redux';
import Aux from "helpers/Aux";
import styles from "./Layout.module.css";
import HeaderBar from '../../components/HeaderBar/HeaderBar';
import SubscribeModal from '../../components/SubscribeModal/SubscribeModal';
import SubscribeButton from '../../components/SubscribeButton/SubscribeButton';
import { AnonContent } from "reaxiom/firebase";

class Layout extends Component {


    render () {

        const modal = this.props.isModalOn === true ? <SubscribeModal/> : null;

        return (
            <Aux>
                <HeaderBar/>
                <main className={styles.Content}>
                    {this.props.children}
                </main>
                {modal}
                <AnonContent>
                    <SubscribeButton/>
                </AnonContent>
            </Aux>
        )
    }
}

const mapStateToProps = state =>{
    return {
        isModalOn: state.subscribeModal.isModalOn,
    }
};

const mapDispatchToProps = dispatch =>{
    return {
        // onToggleModal: () => dispatch(subscribeButton.toggleModal())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
