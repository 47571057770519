import React, { Component } from "react";
import { SigninForm } from "reaxiom/firebase"
import styles from "./Signin.module.css";

class Signin extends Component{

    render(){
        return(
            <div className={styles.Container}>
                <SigninForm/>
            </div>
        );
    }

}

export default Signin;