import React from "react";
import { Link } from "react-router-dom"
import { connect } from "react-redux";

const AuthLink = (props) => {

    if(props.session){ 
        return null
    }else{
        return <Link to={props.to}>{props.children}</Link>;
    }
    
}

const mapStateToProps = state =>{
    return {
        session: state.firebase.session,
    }
};
  
export default connect(mapStateToProps)(AuthLink);