import { Component } from "react";
import { connect } from "react-redux"
import * as firebaseActions from "reaxiom/firebase/firebase-store/actions/firebaseActions";

class ReaxiomApp extends Component {

  componentDidMount() {
    this.authListener = this.props.firebase.onAuthUserListener(
      authUser => {
        this.props.firebase.role(authUser.uid).get().then(roles =>{
          if(roles.exists){
            this.props.setCurrentUserRoles(roles.data());
          }else{
            this.props.setCurrentUserRoles(null);
          }
        }).catch(error => error);
        localStorage.setItem('authUser', JSON.stringify(authUser));
        this.props.setSession(authUser);
      },
      () => {
        localStorage.removeItem('authUser');
        this.props.setSession(null);
      },
    )
  }

  componentWillUnmount() {
    this.authListener();
  }

  render(){
      return this.props.children;
  }

}

const mapStateToProps = state =>{
  return {
      session: state.firebase.session,
      sessionInit: state.firebase.sessionInit,
      firebase: state.firebase.api
  }
};

const mapDispatchToProps = dispatch =>{
  return {
      setSession: (session) => dispatch(firebaseActions.setSession(session)),
      setCurrentUserRoles: (currentUserRoles) => dispatch(firebaseActions.setCurrentUserRoles(currentUserRoles))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ReaxiomApp);