import React from 'react';
import styles from './Presentation.module.css';

const Presentation = (props) => {
    
    return (
        <div className={styles.Container}>
            {/* <h1 className={styles.PresentationTitle}>{props.datas.title}</h1>
            <p className={styles.PresentationContent}>{props.datas.content}</p> */}
            <p className={styles.PresentationContent} dangerouslySetInnerHTML={{ __html: props.datas.content.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p>
        </div>
    )
}



export default Presentation;

