import React from "react";
import styles from "./HeroBanner.module.css";
import Hero from "../../assets/images/HeroFFPA2.jpg"

const heroBanner = (props) => {

    return(
        <div className={styles.Container}>
            <img src={Hero} alt='festival du film en plein air'/>
        </div>
    );

}

export default heroBanner;
