import * as actionTypes from "./actionTypes";

export const toggleModal = () =>{
    return {
        type: actionTypes.TOGGLE_MODAL,
    }
};

export const selectSubDates = (dates) =>{
    return {
        type: actionTypes.SELECT_SUB_DATES,
        dates: dates
    }
};

