import React, {Component} from "react";
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { 
    access, 
    withAuthorization, 
} from "reaxiom/firebase";
import styles from './AdminDashboard.module.css';

import Aux from 'helpers/Aux';
import PostForm from "components/Post/PostForm/PostForm";
import PostList from "components/Post/PostList/PostList";
import PostEntity from "entities/PostEntity";
import PresentationEntity from "entities/PresentationEntity";
import DashboardBar from "components/DashboardBar/DashboardBar";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import SubscribersList from 'components/SubscribersList/SubscribersList';
import SubscribersEntity from "entities/SubscribersEntity";
import PresentationForm from "components/Presentation/PresentationForm/PresentationForm"
import FilmList from "components/Film/FilmList/FilmList";
import FilmEntity from "entities/FilmEntity";
import FilmForm from "components/Film/FilmForm/FilmForm";


class AdminDashboard extends Component {

    state = {
        currentOption : 'INSCRITS',
        loading: true,
        users: null,
        posts: null,
        subscribers: null,
        postEditMode: false,
        postToEditId: null,
        postToEdit: null,
        postToEditLoaded: false,
        presentation : null,
        films: null
    }

    componentDidMount(){
        this.postsUnsub = PostEntity.getAllInRealTime(this.props.firebase, this.setPosts);
        this.subscriberUnsub = SubscribersEntity.getAllInRealTime(this.props.firebase, this.setSubs);
        this.filmsUnsub = FilmEntity.getAllInRealTime(this.props.firebase, this.setFilms);
        PresentationEntity.getOne(this.props.firebase, 'presentation', this.setPresentation)
    }

    createFixtures(){
        const films = {
            0:{
                title: 'Titanic 1',
                diffusion: '14',
                day: '5',
                coverUrl: null
            },
            1:{
                title: 'Titanic 2',
                diffusion: '16',
                day: '5',
                coverUrl: null
            },
            2:{
                title: 'Titanic 3',
                diffusion: '18',
                day: '5',
                coverUrl: null
            },
            3:{
                title: 'Titanic 4',
                diffusion: '21',
                day: '5',
                coverUrl: null
            },
            4:{
                title: 'Titanic 5',
                diffusion: '14',
                day: '6',
                coverUrl: null
            },
            5:{
                title: 'Titanic 6',
                diffusion: '16',
                day: '6',
                coverUrl: null
            },
            6:{
                title: 'Titanic 7',
                diffusion: '18',
                day: '6',
                coverUrl: null
            },
            7:{
                title: 'Titanic 8',
                diffusion: '20',
                day: '6',
                coverUrl: null
            },
            8:{
                title: 'Titanic 9',
                diffusion: '14',
                day: '7',
                coverUrl: null
            },
            9:{
                title: 'Titanic 10',
                diffusion: '16',
                day: '7',
                coverUrl: null
            },
            10:{
                title: 'Titanic 11',
                diffusion: '18',
                day: '7',
                coverUrl: null
            },
            11:{
                title: 'Titanic 12',
                diffusion: '20',
                day: '7',
                coverUrl: null
            },
            12:{
                title: 'Titanic 13',
                diffusion: '14',
                day: '8',
                coverUrl: null
            },
            13:{
                title: 'Titanic 14',
                diffusion: '16',
                day: '8',
                coverUrl: null
            },
            14:{
                title: 'Titanic 15',
                diffusion: '18',
                day: '8',
                coverUrl: null
            },
            15:{
                title: 'Titanic 16',
                diffusion: '20',
                day: '8',
                coverUrl: null
            }

        }

        for(let i=0; i<15; i++){
            FilmEntity.create(this.props.firebase, films[i])
        }
    }

    componentWillUnmount(){
        this.postsUnsub();
        this.subscriberUnsub();
        this.filmsUnsub();
    }

    componentDidUpdate(){
        if(this.state.postToEditId && !this.state.postToEditLoaded){
            PostEntity.getOne(this.props.firebase, this.state.postToEditId, this.setPostToEdit);
        }

    }

    setPostToEdit = (post) => {
        this.setState({
            postToEdit: post,
            postToEditLoaded: true
        })
    }

    setPosts = (posts) => {
        this.setState({
            posts: posts,
            loading: false
        })
    }

    setUsers = (users) => {
        this.setState({
            users: users,
            loading: false
        })
    }

    setSubs = (subscribers) => {
        this.setState({
            subscribers: subscribers,
            loading: false
        })
    }

    setCurrentOption = (option) => {
        this.setState({
            currentOption: option,
            postEditMode: false,
            postToEditId: null,
            postToEdit: null,
            postToEditLoaded: false,
        })
    }

    onPostEditMode = (postId) => {
        if(this.state.postToEditId !== postId){
            this.setState({
                postEditMode: true,
                postToEditId: postId,
                postToEditLoaded: false,
                postToEdit: null,
            })
        }else{
            this.setState({
                postEditMode: true,
                postToEditId: postId
            })
        }
    }

    quitEditMode = () => {
        this.setState({
            postEditMode: false,
            postToEditId: null,
            postToEdit: null,
            postToEditLoaded: false
        })
    }

    setPresentation = (presentation) => {
        this.setState({
            presentation: presentation,
        })
    }

    setFilms = films => {
        this.setState({
            films: films
        })
    }

    render(){
        let adminBoard = <h1>Bienvenue !</h1>
        switch (this.state.currentOption){
            case 'INSCRITS' : adminBoard  = this.state.subscribers ? 
                        <SubscribersList key={Math.random()} datas={this.state.subscribers}/> : 
                        <h1>Il n'y a pas d'inscrits</h1>;
            break;
            case 'PRESENTATION' : adminBoard  = <PresentationForm/>
            break;
            case 'ACTUS' : adminBoard  = this.state.posts ? 
                (<Aux>
                    <PostForm editMode={this.state.postEditMode} postToEdit={this.state.postToEdit} quitEditMode={this.quitEditMode}/>
                    <h2>Liste des actualités</h2>
                    <PostList key={Math.random()} datas={this.state.posts} adminMode toggleEditMode={this.onPostEditMode}/>
                </Aux>):(<PostForm/>); 
            break;
            case 'PROGRAMME' : adminBoard  = 
            
            (   <Aux>
                     <h2>Ajouter un film</h2>
                    <FilmForm createMode key={Math.random()}/>
                    <h2>Liste des films</h2>
                    <FilmList datas={this.state.films} adminMode key={Math.random()}/>
                </Aux>
            )
            break;
            default: adminBoard = <h1>Welcome back user</h1>;
        }
        
        return(
            <div className={styles.Container}>
                <DashboardBar onChangeOption={this.setCurrentOption}/>
                <div className={styles.SubContainer}>
                    {this.state.loading ? 
                        <LoadingSpinner/> :
                        adminBoard
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        session: state.firebase.session,
        firebase: state.firebase.api
    }
}

export default compose(
    connect(mapStateToProps),
    withAuthorization(access.ADMIN)
)(AdminDashboard)
