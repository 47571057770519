class PresentationEntity {

    static getOne = (db, id, callback) => {
        return db.presentation(id).get().then(doc => {
            if(doc.exists){
                callback(doc.data());
            }else{
                callback(null);
            }
        }).catch(e => console.log(e));
    }


    static update = (db, id, data) => {
        db.presentation(id).update({
            ...data,
        })
    }

  
}

export default PresentationEntity;