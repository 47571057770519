import React from 'react';
import styles from './Film.module.css';
import {connect} from 'react-redux';

const Film = (props) => {

    let minutageFormated = props.datas.minutage === 0 ? '00' : props.datas.minutage; 

    const post = props.adminMode ? 
        (
        <div className={styles.Container} style={{ 
                    backgroundImage: 'url('+props.datas.coverUrl+')',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'}}>
                <div className={styles.FilmDescriptionContainer}>
                    <p className={styles.FilmTitle}>{props.datas.title}</p>
                    <p className={styles.FilmTime}>{props.datas.diffusion}H{minutageFormated}</p>
                </div>
        </div>
        ) :
        (
        <div className={styles.Container} style={{ 
                    backgroundImage: 'url('+props.datas.coverUrl+')',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'}}>
                <div className={styles.FilmDescriptionContainer}>
                    <p className={styles.FilmTitle}>{props.datas.title}</p>
                    <p className={styles.FilmTime}>{props.datas.diffusion}H{minutageFormated}</p>
                </div>
        </div>
        );


    return post;
}

const mapStateToProps = state => {
    return {
        session: state.firebase.session,
        firebase: state.firebase.api
    }
}

export default connect(mapStateToProps)(Film);

// const newsCard = (props) => {

//     return(
//         <div className={styles.Container}>
//             <h2 className={styles.NewsTitle}>{props.newsTitle}</h2>
//             <p className={styles.NewsContent}>{props.newsContent}</p>
//             <p className={styles.NewsDate}>Posté le : {props.newsDate}</p>
//         </div>
//     );

// }

// export default newsCard;

//<span className={styles.Date}>{new Date(props.datas.createdAt.seconds*1000).toString()}</span>