import React from "react";
import styles from "./SubscribeButton.module.css";
import { connect } from "react-redux";
import * as subscribeButtonActions from "../../store/actions/actions";

import Aux from 'helpers/Aux';
import Button from '@material-ui/core/Button';

const subscribeButton = (props) => {

	const style = {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 180,
        border: 0,
        color: 'white',
		height: 150,
        width: 150,
		boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
		fontSize: '1.4em',
		fontWeight: 'bold',
	};
	
	const styleMobile = {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 10,
        border: 0,
        color: 'white',
		height: 50,
        width: '90%',
        padding: '0 0',
		boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
		fontSize: '1.2em',
		fontWeight: 'bold',
	};
	
    return(
		<Aux>
			<div className={styles.Container}>
				<Button className={styles.BtnAnim} style={style} onClick={props.onToggleModal}>RESERVER !</Button>
			</div>
			<div className={styles.ContainerMobile}>
				<Button className={styles.BtnAnim} style={styleMobile} onClick={props.onToggleModal}>RESERVEZ VOTRE PLACE !</Button>
			</div>
		</Aux>
    );

}

const mapStateToProps = state =>{
 	return {
 		isModalOn: state.subscribeModal.isModalOn,
 	}
 };
 
 const mapDispatchToProps = dispatch =>{
 	return {
 		onToggleModal: () => dispatch(subscribeButtonActions.toggleModal())
 	}
 };
 
 export default connect(mapStateToProps, mapDispatchToProps)(subscribeButton);
