import React from "react";
import styles from "./SectionHeader.module.css";

const sectionHeader = (props) => {

    return(
        <div className={styles.Container}>
            <h2>{props.children}</h2>
        </div>
    );

}

export default sectionHeader;
