import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styles from "./SignupForm.module.css";

class SignupForm extends Component{

    state = {
        username: '',
        email: '',
        password: '',
        passwordConfirm: '',
        error: null
    }

    onSubmit = event => {
        const { username, email, password } = this.state;
        
        this.props.firebase
            .doCreateUserWithEmailAndPassword(email, password)
            .then(authUser => {
                return this.props.firebase
                    .user(authUser.user.uid)
                    .set({
                        username,
                        email,
                    },
                        {merge: true}
                    )
            })
            .then(() => {
                return this.props.firebase.doSendEmailVerification();
            })
            .then(() => {
                this.setState({ 
                    username: '',
                    email: '',
                    password: '',
                    passwordConfirm: '',
                    error: null
                })
                this.props.history.push('/');
            })
            .catch(error => {
                this.setState({ error: error });
            })

        event.preventDefault();
    }

    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    render(){
        const {
            username,
            email,
            password,
            passwordConfirm,
            error,
        } = this.state;

        const isInvalid =
            password !== passwordConfirm ||
            password === '' ||
            email === '' ||
            username === '';

        return(
            <div className={styles.SignupForm}>
                <form onSubmit={event => this.onSubmit(event)} className={styles.Form}>
                    <h2>Sign Up</h2>
                    <div className={styles.InputGroup}>
                        <input
                            name="username"
                            value={username}
                            onChange={this.onChange}
                            type="text"
                            placeholder="Username"
                        />
                        <input
                            name="email"
                            value={email}
                            onChange={this.onChange}
                            type="text"
                            placeholder="Email Address"
                        />
                        <input
                            name="password"
                            value={password}
                            onChange={this.onChange}
                            type="password"
                            placeholder="Password"
                        />
                        <input
                            name="passwordConfirm"
                            value={passwordConfirm}
                            onChange={this.onChange}
                            type="password"
                            placeholder="Confirm Password"
                        />
                    </div>
                    {error && <p className={styles.error}>{error.message}</p>}
                    <button disabled={isInvalid} type="submit" className={styles.Button+' '+styles.ripple}>
                        Sign Up
                    </button>
                </form>
            </div>
        );
    }

}

const mapStateToProps = state =>{
    return {
        session: state.firebase.session,
        firebase: state.firebase.api
    }
};

export default compose(
    connect(mapStateToProps),
    withRouter
)(SignupForm)
