import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
//CloudStorage
import 'firebase/storage';
//End CloudStorage
import { sdkConfig, emailConfirmationRedirectUrl } from "../config/config";
import { EntryPoints } from "reaxiomConfig";

class Firebase extends EntryPoints{

    constructor() {
        super();
        app.initializeApp(sdkConfig);
        this.auth = app.auth();
        this.db = app.firestore();
        //CloudStorage
        this.storage = app.storage();
        //End CloudStorage
        this.fieldValue = app.firestore.FieldValue;
        this.googleProvider = new app.auth.GoogleAuthProvider();
    }

    //** Firebase Authentification API */
    doCreateUserWithEmailAndPassword = (email, password) => 
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) => 
        this.auth.signInWithEmailAndPassword(email, password);

    doSignInWithGoogle = () => 
        this.auth.signInWithPopup(this.googleProvider);
    
    doSignOut = () => {
        this.auth.signOut();
    }
        
    doPasswordReset = email => 
        this.auth.sendPasswordResetEmail(email);
    
    doPasswordUpdate = password => 
        this.auth.currentUser.updatePassword(password);

    doSendEmailVerification = () => 
        this.auth.currentUser.sendEmailVerification({
            url: emailConfirmationRedirectUrl
    });

    //** User API
    user = (uid) => this.db.doc(`users/${uid}`);
    users = () => this.db.collection('users');
    //** Role API */
    role = (uid) => this.db.doc(`roles/${uid}`);
    roles = () => this.db.collection('roles');

    //** Register auth user in the firestore database */
    onAuthUserListener = (next, fallback) =>
        this.auth.onAuthStateChanged(authUser => {
            if (authUser) {
                this.user(authUser.uid)
                .get()
                .then(snapshot => {
                    const dbUser = snapshot.data();
                    authUser = {
                        uid: authUser.uid,
                        email: authUser.email,
                        emailVerified: authUser.emailVerified,
                        providerData: authUser.providerData,
                        ...dbUser,
                    };
                    next(authUser);
                });
            } else {
                fallback();
            }
    });

    //** STORAGE API */
    getStoredImageUrl = (filename, callback) =>
        this.imagesFolder()
            .child(filename)
            .getDownloadURL()
            .then( url => callback(url) );
    

    storeFile = (file, path, callback) => 
        this.storagePath(path)
            .put(file)
            .then(snapshot => 
                callback(snapshot)
            )
            .catch(e => 
                console.log(e)
            );
    
}

export default Firebase;