const reaxiomConfig = {
    sdkConfig : {
        firebase: {
            apiKey: "AIzaSyDIMy9kH1v_YSdzgidBpsvTSV0tmp9tM1I",
            authDomain: "film-plein-air-b02cb.firebaseapp.com",
            databaseURL: "https://film-plein-air-b02cb.firebaseio.com",
            projectId: "film-plein-air-b02cb",
            storageBucket: "film-plein-air-b02cb.appspot.com",
            messagingSenderId: "1003862122864",
            appId: "1:1003862122864:web:6036e6820e911de4"
        }
    },
    routes : {
        rootRedirection: "/",
        signin: "/signin",
        signinRedirect: "/admin",
        passwordForget: "/pw-forget",
        emailConfirmationRedirectUrl: "http://localhost:3000"
    },
    authorization:{
        roles:{
            ADMIN: "ADMIN",
            EDITOR: "EDITOR"
        },
        access:{
            SIGNED_IN: authUser => !!authUser,
            ADMIN: (authUser, currentUserRoles ) => 
                authUser && currentUserRoles && currentUserRoles[reaxiomConfig.authorization.roles.ADMIN],
            EDITOR: (authUser, currentUserRoles ) => 
                authUser && currentUserRoles && currentUserRoles[reaxiomConfig.authorization.roles.EDITOR],
            VERIFIED: authUser => 
                authUser && !!authUser.emailVerified && authUser.providerData
                        .map(provider => provider.providerId)
                        .includes('password'), 
        }
    },
    misc : {
        language: "FR"
    },
}

export class EntryPoints{

    //FIREBASE FIRESTORE ENTRY POINTS
    post = uid => this.db.doc(`posts/${uid}`);
    posts = () => this.db.collection('posts');
    message = uid => this.db.doc(`messages/${uid}`);
    messages = () => this.db.collection('messages');
    subscriber = uid => this.db.doc(`subscribers/${uid}`);
    subscribers = () => this.db.collection('subscribers');
    presentation = uid => this.db.doc(`presentation/${uid}`);
    presentations = () => this.db.collection('presentation/presentation');
    film = uid => this.db.doc(`films/${uid}`);
    films = () => this.db.collection('films');

    //CLOUD STORAGE ENTRY POINTS
    storagePath = path => this.storage.ref(`${path}`)
    imagesFolder = () => this.storage.ref(`/images/`)

}

export default reaxiomConfig; 


