import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styles from "./PostForm.module.css";

import PostEntity from "entities/PostEntity";

class PostForm extends Component{

    state = {
        title: '',
        content: '',
        error: null,
        editPostId: null,
        editPostLoaded: false,
        editMode: false,
        file: null,
        coverUrl: ''
    }

    onSubmit = event => {
        let uid = Math.round(Math.random()*1000000000000)
        let path = '/images/'+uid
        
        if(this.state.file){
            this.props.firebase.storeFile(this.state.file, path, upload => {
                this.props.firebase.getStoredImageUrl(uid.toString(), url => {
                    if(this.state.editMode){
                        PostEntity.update(this.props.firebase, this.state.editPostId, {
                            title: this.state.title,
                            content: this.state.content,
                            coverUrl: url
                        })
                        this.props.quitEditMode();
                    }else{
                        PostEntity.create(this.props.firebase, this.props.session, {
                            title: this.state.title,
                            content: this.state.content,
                            coverUrl: url
                        })
                    }
                })
            })
        }else{
            if(this.state.editMode){
                PostEntity.update(this.props.firebase, this.state.editPostId, {
                    title: this.state.title,
                    content: this.state.content,
                    coverUrl: this.state.coverUrl
                })
                this.props.quitEditMode();
            }else{
                PostEntity.create(this.props.firebase, this.props.session, {
                    title: this.state.title,
                    content: this.state.content,
                    coverUrl: null
                })
            }
        }
        event.preventDefault();
    }

    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    setUpImage = event => {
        this.setState({
            file : event.target.files[0],
            fileInit: true
        })
    }

    componentDidUpdate(){
        if(!this.state.editMode && this.props.postToEdit){
            let post = {...this.props.postToEdit}
            this.setState({
                title: post.title,
                content: post.content,
                editPostId: post.postId,
                coverUrl: post.coverUrl,
                editMode: true
            })
        }else if(this.state.editMode && !this.props.postToEdit){
            this.setState({
                title: '',
                content: '',
                error: null,
                editPostId: null,
                editPostLoaded: false,
                editMode: false,
                coverUrl: null
            })
        }
    }

    quitEditMode = () => {
       this.props.quitEditMode();
    }

    render(){
        const { title, content, error } = this.state;
        const isInvalid = title === '' || content ==='';

        return(
            <div className={styles.PostForm}>
                <form onSubmit={event => this.onSubmit(event)} className={styles.Form}>
                    {!this.props.editMode ? <h2>Créer une actualité</h2> : <h2>Modifier une actualité</h2>}
                    <div className={styles.InputGroup}>
                        <input
                            name="title"
                            value={title || ''}
                            onChange={this.onChange}
                            type="text"
                            placeholder="Titre"
                        />
                        <textarea
                            name="content"
                            value={content || ''}
                            onChange={this.onChange}
                            placeholder="Contenu"
                        />
                        <label className={styles.InputFileLabel}>
                            <input
                                id="file-upload"
                                name="image"
                                onChange={this.setUpImage}
                                type="file"
                            />
                            {this.props.editMode ? "Changer l'image de l'actu" : "Choisir l'image de l'actu"}
                        </label>
                        {!this.props.editMode ? null : <span className={styles.QuitEdit} onClick={() => this.quitEditMode()}>Quitter le mode édition</span>}
                        {error && <p className={styles.error}>{error.message}</p>}
                    </div>
                    <button disabled={isInvalid} type="submit" className={styles.Button+' '+styles.ripple}>
                        Enregistrer
                    </button>
                </form>
            </div>
        );
    }

}

const mapStateToProps = state =>{
    return {
        session: state.firebase.session,
        firebase: state.firebase.api
    }
};

export default compose(
    connect(mapStateToProps),
    withRouter
)(PostForm)
