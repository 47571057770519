import React from "react";
import styles from "./NavLinks.module.css";
import { withRouter } from "react-router-dom";
import {AuthContent, roles} from "reaxiom/firebase"
import {connect} from "react-redux";

import Button from '@material-ui/core/Button';


const navLinks = (props) => {

    const style = {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 10,
        border: 0,
        color: 'white',
        width: '160px',
        height: '50px',
        padding: '10px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        margin: '0 10px',
        opacity:'0.5'
    };

    const styleActive = {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 10,
        border: 0,
        color: 'white',
        width: '160px',
        height: '50px',
        padding: '10px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        margin: '0 10px',
        opacity:'1',
        fontWeight: 'bold',
        fontFamily: "Roboto', sans-serif",
    }

    const pageChange = (btnType) => {
        switch(btnType){
            case "ACTU" : props.history.push('/');
            break;
            case "PROG" : props.history.push('/films');
            break;
            case "ADMIN" : props.history.push('/admin');
            break;
            default: return;
        }
    }

    return(
        <div className={styles.Container}>
            <Button onClick={() => pageChange('ACTU')} style={props.history.location.pathname === "/" ? styleActive : style} >Actus</Button>
            <Button onClick={() => pageChange('PROG')} style={props.history.location.pathname === "/films" ? styleActive : style}>Programme</Button>
            <AuthContent roles={roles.ADMIN}><Button onClick={() => pageChange('ADMIN')} style={props.history.location.pathname === "/admin" ? styleActive : style}>Administration</Button></AuthContent>
            <AuthContent><Button onClick={() => props.firebase.doSignOut()} style={styleActive}>Se déconnecter</Button></AuthContent>
        </div>
    );

}

const mapStateToProps = state => {
    return {
        session: state.firebase.session,
        firebase: state.firebase.api
    }
}

export default connect(mapStateToProps)(withRouter(navLinks));
