class PostEntity {

    constructor(data){
        this.postId = data.postId;
        this.title = data.title;
        this.content = data.content;
        this.userId = data.uid;
        this.createdAt = data.createdAt;
        this.editedAt = data.editedAt;
    }

    static getAllInRealTime = (db, callback) => {
        return db.posts()
        .orderBy('createdAt', 'desc')
        .onSnapshot(snapshot => {
            if(snapshot.size){
                snapshot.forEach(doc => {
                        let posts = [];
                        snapshot.forEach(doc => 
                            posts.push({ ...doc.data(), uid: doc.id })
                        );
                        callback(posts);
                })
            } else {
                callback(null)
            } 
        })
    }

    static getAll = (db, callback) => {
        return db.posts()
        .orderBy('createdAt', 'desc')
        .get()
        .then(snapshot => {
            const posts = [];
            snapshot.forEach(doc => {
                posts.push({...doc.data()})
            })
            return posts;
        }).then(posts => {
            callback(posts)
        }
        ).catch(error => error)
    }

    static getOne = (db, id, callback) => {
        return db.post(id).get().then(doc => {
            if(doc.exists){
                callback(doc.data());
            }else{
                callback(null);
            }
        }).catch(e => e);
    }

    static create = (db, session, data) => {
        db.posts().add({
                title: data.title,
                content: data.content,
                userId: session.uid,
                createdAt: db.fieldValue.serverTimestamp(),
                coverUrl: data.coverUrl
            }).then(docRef => {
                db.post(docRef.id).update({
                    postId: docRef.id
                })
            });
    } 

    static update = (db, id, data) => {
        db.post(id).update({
            ...data,
            editedAt: db.fieldValue.serverTimestamp()
        })
    }

    static delete = (db, id) => {
        db.post(id).delete();
    }

}

export default PostEntity;