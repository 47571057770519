import React from "react";
import ReaxiomApp from "reaxiom/app"
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import {Provider} from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import HelloButton_R from "./store/reducers/HelloButton_R";
import ByeButton_R from "./store/reducers/ByeButton_R";
import DateSelector_R from "./store/reducers/DateSelector_R";
import SubscribeModal_R from "./store/reducers/SubscribeModal_R";
import { FirebaseReducer } from "reaxiom/firebase"

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
   helloButton : HelloButton_R,
   byeButton : ByeButton_R,
   dateSelector: DateSelector_R,
   subscribeModal: SubscribeModal_R,
   firebase: FirebaseReducer
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <ReaxiomApp>
                <App/>
            </ReaxiomApp>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

serviceWorker.unregister();
