import React from "react";
import styles from "./HeaderBar.module.css";

import Aux from 'helpers/Aux';
import Logo from "./Logo/Logo"
import NavTabs from "./NavTabs/NavTabs";
import NavLinks from "./NavLinks/NavLinks";

const headerBar = (props) => {

    return(
        <Aux>
            <div className={styles.Container}>
                <Logo/>
                <NavLinks/>
            </div>
            <NavTabs/>
        </Aux>

    );

}

export default headerBar;
