import React, {Component} from "react";
import styles from "./SubscribersList.module.css";
import {connect} from "react-redux";
import SubscriberEntity from "entities/SubscribersEntity";

class SubscribersList extends Component {

    state = {
        initialized: false,
        nbForD1: 0,
        nbForD2: 0,
        nbForD3: 0,
        nbForD4: 0,
    }

    n1 = 0;
    n2 = 0;
    n3 = 0;
    n4 = 0; 

    addNbForD1(factor){
        this.n1 = this.n1 + parseInt(factor)
    }
    addNbForD2(factor){
        this.n2 = this.n2 + parseInt(factor)
    }
    addNbForD3(factor){
        this.n3 = this.n3 + parseInt(factor) 
    }
    addNbForD4(factor){
        this.n4 = this.n4 + parseInt(factor)
    }

    componentDidMount(){
        if(!this.state.initialized){
            this.setState({
                initialized: true,
                nbForD1: this.n1,
                nbForD2: this.n2,
                nbForD3: this.n3,
                nbForD4: this.n4,
            })
        }        
    }

    deleteSub = (userId) => {
        console.log(userId)
        SubscriberEntity.delete(this.props.firebase, userId);
    }

    render(){
        return(
            <div className={styles.Container}>
                <h1>Liste des inscriptions</h1>
    
                <div className={styles.Globals}>
                    <div className={styles.Numbers}>
                        <p>5 Août</p>
                        <p>{this.state.nbForD1}</p>
                    </div>
                    <div className={styles.Numbers}>
                        <p>6 Août</p>
                        <p>{this.state.nbForD2}</p>
                    </div>
                    <div className={styles.Numbers}>
                        <p>7 Août</p>
                        <p>{this.state.nbForD3}</p>
                    </div>
                    <div className={styles.Numbers}>
                        <p>8 Août</p>
                        <p>{this.state.nbForD4}</p>
                    </div>
                    <div className={styles.Numbers}>
                        <p>Total</p>
                        <p>{this.state.nbForD1 + this.state.nbForD2 + this.state.nbForD3 +this.state.nbForD4}</p>
                    </div>
                </div>
    
                 <div className={styles.TitleContainer}>
                            <span className={styles.TitleEmail}><strong>Email</strong></span>
                            <div className={styles.TitleDays}>Jours de présence</div>
                            <span className={styles.TitleSubs}>Nombre de réservations</span>
                    </div>
                {
                    this.props.datas.map(data => 
                        <div className={styles.SubscriberContainer} key={data.email}>
                                <span className={styles.SubEmail}>{data.email}</span>
                                <div className={styles.SubDays}>
                                    {data.subDays.day1 ? 
                                        <span className={styles.J1} data-addad1={this.addNbForD1(data.nbOfSub)}> 05 </span> : 
                                        <span className={styles.J1off}> 05 </span>
                                    }
                                    {data.subDays.day2 ? 
                                        <span className={styles.J2} data-addad1={this.addNbForD2(data.nbOfSub)}> 06 </span> : 
                                        <span className={styles.J2off}> 06 </span>
                                    }
                                    {data.subDays.day3 ? 
                                        <span className={styles.J3} data-addad1={this.addNbForD3(data.nbOfSub)}> 07 </span> : 
                                        <span className={styles.J3off}> 07 </span>
                                    }
                                    {data.subDays.day4 ? 
                                        <span className={styles.J4} data-addad1={this.addNbForD4(data.nbOfSub)}> 08 </span> : 
                                        <span className={styles.J4off}> 08 </span>
                                    }
                                </div>
                                <span className={styles.NbOfSubs}><strong>{data.nbOfSub}</strong></span>
                                <span className={styles.Delete} onClick={() => this.deleteSub(data.email)}>Supprimer</span>
                        </div>
                    )
                }
            </div>
            
        )
    }
}

const mapStateToProps = state => {
    return {
        session: state.firebase.session,
        firebase: state.firebase.api
    }
}

export default connect(mapStateToProps)(SubscribersList);
