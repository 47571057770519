import * as actionTypes from "../actions/actionTypes";
import objectUpdater from "../../helpers/ObjectUpdater"

const initialState = {
    selectedDate: 5
};

const selectDate = ( state, action ) => {
    const updatedState = {
        selectedDate: action.date
    };
    return objectUpdater( state, updatedState );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SELECT_DATE: return selectDate(state, action);
        default: return state;
    }
};

export default reducer;
