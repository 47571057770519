import React, { Component } from "react";
import styles from "./SubscribeModal.module.css";
import { connect } from "react-redux";
import * as subscribeModalActions from "../../store/actions/actions";
import Aux from "helpers/Aux";

import SubscriberEntity from "entities/SubscribersEntity";

class subscribeModal extends Component {

	state = {
		subDays: {
			day1: false,
			day2: false,
			day3: false,
			day4: false,
		},
		email: '',
		nbOfSub: 1,
		isSubmited: false
	}

	setSubDays = (selectedDate) => {
		this.setState({
			subDays:{
				...this.state.subDays,
				[selectedDate]: !this.state.subDays[selectedDate]
			}
		});
	}

	onChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
	};
	
	subscribe = () => {
		const { subDays, email, nbOfSub } = this.state;
		let isInvalid = 
			subDays === { day1: false, day2: false, day3: false, day4: false,} ||
			email === '' || 
			nbOfSub ==='';

		if(isInvalid){
			alert('Tous les champs sont obligatoires');
			return;
		}else{
			// eslint-disable-next-line
			const emailValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
			if(!email.match(emailValid)){
				alert(`L'adresse email n'est pas valide`);
				return;
			}
			if(nbOfSub > 5){
				alert(`Vous ne pouvez pas réserver pour plus de 5 personnes par adresse email`);
				return;
			}
			SubscriberEntity.create(this.props.firebase,this.state);
			this.setState({
				isSubmited: true
			})
			setTimeout(()=>{
				let closeBtn = document.getElementById('closeBtn');
				closeBtn.click();
			}, 1000)
			return;
		}
	}

	render(){
		const modalContent = this.state.isSubmited ? 
			<h2>Merci vous êtes inscrit !</h2>
		:
		<Aux>
			<span className={styles.subInfo}>Votre réservation sera mise à jour si vous utilisez la même adresse</span>
			<span>Choisissez vos dates</span>
			<div className={styles.Selector}>
				<button onClick={() => this.setSubDays('day1')}
				className={this.state.subDays.day1 ? styles.Selected : null}>05/08</button>
				<button onClick={() => this.setSubDays('day2')}
				className={this.state.subDays.day2 ? styles.Selected : null}>06/08</button>
				<button onClick={() => this.setSubDays('day3')}
				className={this.state.subDays.day3 ? styles.Selected : null}>07/08</button>
				<button onClick={() => this.setSubDays('day4')}
				className={this.state.subDays.day4 ? styles.Selected : null}>08/08</button>
			</div>
			<span>Renseignez votre email </span>
			<div className={styles.EmailForm}>
				<input 
				name='email'
				type='text' 
				placeholder='moi@adresse.com' 
				className={styles.EmailInput}
				value={this.state.email}
				onChange={this.onChange}/>
			</div>
			<span>Combien serez-vous ? </span>
			<div className={styles.EmailForm}>
				<input
				name='nbOfSub' 
				type='text' 
				placeholder='1'
				onChange={this.onChange} 
				value={this.state.nbOfSub}
				className={styles.NbSubInput}/>
			</div>
		</Aux>
		;
		
		return(
			<div className={styles.Container}>
				<div className={styles.Modal+' '+styles.ModalActive}>
					<div className={styles.ModalHeader}>
						<span>RESERVER MA PLACE</span>
						<span onClick={this.props.onToggleModal} id='closeBtn'>x</span>
					</div>
					<div className={styles.ModalBody}>
						{modalContent}
					</div>
					<div className={styles.ModalFooter} onClick={() => this.subscribe()}>S'INSCRIRE</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
    return {
        session: state.firebase.session,
		firebase: state.firebase.api,
		selectedSubDates : state.subscribeModal.selectedSubDates
    }
}

const mapDispatchToProps = dispatch =>{
	return {
		onToggleModal: () => dispatch(subscribeModalActions.toggleModal()),
		onSelectSubDates: () => dispatch(subscribeModalActions.selectSubDates())
	}
};
 
 export default connect(mapStateToProps, mapDispatchToProps)(subscribeModal);
