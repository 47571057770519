import React from "react";
import styles from "./Logo.module.css";

import { Link } from "react-router-dom";

const logo = (props) => {

    return(
        <Link to={'/'}>
            <div className={styles.Container}>
                <p>FeStIvAL Du</p>
                <p>FiLm En</p>
                <p>PlEiN AiR</p>
            </div>
        </Link>

    );

}

export default logo;
