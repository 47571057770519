class SubscriberEntity {

    static getAllInRealTime = (db, callback) => {
        return db.subscribers()
        .orderBy('createdAt', 'desc')
        .onSnapshot(snapshot => {
            if(snapshot.size){
                snapshot.forEach(doc => {
                        let subscribers = [];
                        snapshot.forEach(doc => 
                            subscribers.push({ ...doc.data(), uid: doc.id })
                        );
                        callback(subscribers);
                })
            } else {
                callback(null)
            } 
        })
    }

    static getAll = (db, callback) => {
        return db.subscribers()
            .get()
            .then(snapshot => {
                const subscribers = [];
                snapshot.forEach(doc => {
                    subscribers.push({...doc.data()})
                })
                return subscribers;
            }).then(subscribers => {
                callback(subscribers)
            }
            ).catch(error => error)
    }

    static getOne = (db, id, callback) => {
        return db.subscriber(id).get().then(doc => {
            if(doc.exists){
                callback(doc.data());
            }else{
                callback(null);
            }
        }).catch(e => e);
    }

    static create = (db, data) => {
        db.subscriber(data.email).set({
                subDays: {...data.subDays},
                email: data.email,
                nbOfSub: parseInt(data.nbOfSub),
                createdAt: db.fieldValue.serverTimestamp()
            })
    } 

    static update = (db, id, data) => {
        db.subscriber(id).update({
            ...data,
            editedAt: db.fieldValue.serverTimestamp()
        })
    }

    static delete = (db, id) => {
        db.subscriber(id).delete();
    }

}

export default SubscriberEntity;