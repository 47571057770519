import * as actionTypes from "./actionTypes";

export const setSession = (session) =>{
    return {
        type: actionTypes.SET_SESSION,
        session: session,
    }
};

export const setCurrentUserRoles = (currentUserRoles) =>{
    return {
        type: actionTypes.SET_CURRENT_USER_ROLES,
        currentUserRoles: currentUserRoles,
    }
};