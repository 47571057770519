import React from "react";
import {Route, Switch} from "react-router-dom";
import "./App.css";
import Layout from "./helpers/Layout/Layout";
import NewsFeed from "./pages/front/NewsFeed/NewsFeed";
import FilmsFeed from "./pages/front/FilmsFeed/FilmsFeed";
import AdminDashboard from "pages/back/AdminDashboard/AdminDashboard";
import Signin from "pages/front/Signin/Signin";
import PasswordForget from "pages/front/PasswordForget/PasswordForget";

function App() {
  return (
    <div className="App">
        <Layout>
            <Switch>
                <Route path="/" exact component={NewsFeed}/>
                <Route path="/films" exact component={FilmsFeed}/>
                <Route path="/admin" exact component={AdminDashboard}/>
                <Route path="/signin" exact component={Signin}/>
                <Route path="/pw-forget" exact component={PasswordForget}/>
            </Switch>
        </Layout>
    </div>
  );
}

export default App;
