import React from "react";
import { Link } from "react-router-dom"
import { connect } from "react-redux";

const AuthLink = (props) => {

    if(props.verified){
        if(needsVerifiedEmail(props.session)){
            return null;
        }
    }

    if(props.session && !props.roles){ 
        return <Link to={props.to}>{props.children}</Link>
    }else if(props.session && props.roles){
        if(props.currentUserRoles && props.currentUserRoles[props.roles]){
            return <Link to={props.to}>{props.children}</Link>
        }else{
            return null;
        }
    }else{
        return null;
    }
}

const needsVerifiedEmail = session =>
    session &&
    !session.emailVerified &&
    session.providerData
        .map(provider => provider.providerId)
        .includes('password');

const mapStateToProps = state =>{
    return {
        session: state.firebase.session,
        currentUserRoles: state.firebase.currentUserRoles
    }
};
  
export default connect(mapStateToProps)(AuthLink);