import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { access, signInRedirect } from '../config/config';

const withAuthorization = condition => Component => {

    class WithAuthorization extends React.Component {

        componentDidMount() {
            this.listener = this.props.firebase
                .onAuthUserListener(
                    authUser => {
                        if(!condition(authUser, this.props.currentUserRoles)){
                            setTimeout(() => {
                                if(condition === access.VERIFIED){
                                    this.props.history.push('/');
                                }
                                else{
                                    if(!this.props.currentUserRoles){
                                        this.props.history.push(signInRedirect);
                                    }
                                }
                            }, 500)
                        }
                    },
                    () => this.props.history.push(signInRedirect),
                );
        }

        componentWillUnmount() {
            this.listener();
        }

        render() {
            return (
                condition(this.props.session, this.props.currentUserRoles) ? 
                <Component {...this.props} /> :
                null
            );
        }
    }

    return compose(
        connect(mapStateToProps),
        withRouter,
    )(WithAuthorization);
    
};

const mapStateToProps = state =>{
    return {
        session: state.firebase.session,
        currentUserRoles: state.firebase.currentUserRoles,
        firebase: state.firebase.api
    }
};

export default withAuthorization;