import { connect } from "react-redux";

const AnonContent = (props) => {

    if(props.session){ 
        return null;
    }else{
        return props.children
    }
}

const mapStateToProps = state =>{
    return {
        session: state.firebase.session,
    }
};
  
export default connect(mapStateToProps)(AnonContent);