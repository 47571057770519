class FilmEntity {

    static getAllInRealTime = (db, callback) => {
        return db.films()
        .orderBy('day', 'asc')
        .orderBy('diffusion', 'asc')
        .onSnapshot(snapshot => {
            if(snapshot.size){
                snapshot.forEach(doc => {
                        let films = [];
                        snapshot.forEach(doc => 
                            films.push({ ...doc.data(), uid: doc.id })
                        );
                        callback(films);
                })
            } else {
                callback(null)
            } 
        })
    }

    static getAll = (db, callback) => {
        return db.films()
        .orderBy('day', 'asc')
        .orderBy('diffusion', 'asc')
        .get()
        .then(snapshot => {
            const films = [];
            snapshot.forEach(doc => {
                films.push({...doc.data()})
            })
            return films;
        }).then(films => {
            callback(films)
        }
        ).catch(error => error)
    }

    static getOne = (db, id, callback) => {
        return db.film(id).get().then(doc => {
            if(doc.exists){
                callback(doc.data());
            }else{
                callback(null);
            }
        }).catch(e => e);
    }

    static create = (db, data) => {
        db.films().add({
                title: data.title,
                diffusion: data.diffusion,
                minutage: data.minutage,
                day: data.day,
                coverUrl: data.coverUrl
            }).then(docRef => {
                db.film(docRef.id).update({
                    filmId: docRef.id
                })
            });
    } 

    static update = (db, id, data) => {
        db.film(id).update({
            ...data,
            editedAt: db.fieldValue.serverTimestamp()
        })
    }

    static delete = (db, id) => {
        db.film(id).delete();
    }

}

export default FilmEntity;