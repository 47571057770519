import * as actionTypes from "../actions/actionTypes";
import Firebase from "../../api/firebase";
const initialState = {
    api: new Firebase(),
    session: JSON.parse(localStorage.getItem('authUser')),
    sessionInit: false,
    currentUserRoles: null
};

const setSession = ( state, action ) => {
    const updatedState = {
        session: action.session,
        sessionInit: true,
    };
    return {
        ...state,
        ...updatedState
    };
};

const setCurrentUserRoles = ( state, action ) => {
    const updatedState = {
        currentUserRoles: action.currentUserRoles
    };
    return {
        ...state,
        ...updatedState
    };
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_SESSION: return setSession(state, action);
        case actionTypes.SET_CURRENT_USER_ROLES: return setCurrentUserRoles(state, action);
        default: return state;
    }
};

export default reducer;