import React from "react";
import { connect } from "react-redux";
import EmailVerify from "../EmailVerify/EmailVerify";

const AuthContent = (props) => {

    if(props.verified){
        if(needsVerifiedEmail(props.session)){
            return <EmailVerify/>;
        }
    }

    if(props.session && !props.roles){ 
        return props.children
    }else if(props.session && props.roles){
        if(props.currentUserRoles && props.currentUserRoles[props.roles]){
            return props.children
        }else{
            return null;
        }
    }else{
        return null;
    }
}

const needsVerifiedEmail = session =>
    session &&
    !session.emailVerified &&
    session.providerData
        .map(provider => provider.providerId)
        .includes('password');

const mapStateToProps = state =>{
    return {
        session: state.firebase.session,
        currentUserRoles: state.firebase.currentUserRoles 
    }
};
  
export default connect(mapStateToProps)(AuthContent);