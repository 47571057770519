import React from 'react';
import Post from '../Post';
import styles from './PostList.module.css';

const PostList = (props) => {

    return(
        <div className={styles.Container}>
            {props.datas.map(data => 
                <Post key={Math.random()} datas={data} adminMode={props.adminMode} toggleEditMode={props.toggleEditMode}/>
            )}
        </div>
    )
}

export default PostList;