import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Link, withRouter } from "react-router-dom";
import styles from "./SigninForm.module.css";

class SigninForm extends Component{

    state = {
        email: '',
        password: '',
        error: null
    }

    onSubmit = event => {
        const { email, password } = this.state;
        
        this.props.firebase.doSignInWithEmailAndPassword(email, password)
            .then(() => {
                this.setState({ 
                    email: '',
                    password: '',
                    error: null
                })
                this.props.history.push('/admin');
            })
            .catch(error => {
                this.setState({ error });
            })
        event.preventDefault();
    }

    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    render(){
        const { email, password, error } = this.state;
        const isInvalid = password === '' || email ==='';

        return(
            <div className={styles.SigninForm}>
                <form onSubmit={event => this.onSubmit(event)} className={styles.Form}>
                    <h2>Se connecter</h2>
                    <div className={styles.InputGroup}>
                        <input
                            name="email"
                            value={email}
                            onChange={this.onChange}
                            type="text"
                            placeholder="Email"
                        />
                        <input
                            name="password"
                            value={password}
                            onChange={this.onChange}
                            type="password"
                            placeholder="Password"
                        />
                        <p className={styles.PWForget}><Link to={'/pw-forget'}>Reset du mot de passe...</Link></p>
                        {error && <p className={styles.error}>{error.message}</p>}
                    </div>
                    <button disabled={isInvalid} type="submit" className={styles.Button+' '+styles.ripple}>
                        SignIn
                    </button>
                </form>
            </div>
        );
    }

}

const mapStateToProps = state =>{
    return {
        session: state.firebase.session,
        firebase: state.firebase.api
    }
};

export default compose(
    connect(mapStateToProps),
    withRouter
)(SigninForm)
