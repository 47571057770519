import React, {Component} from "react";
import styles from "./FilmsFeed.module.css";
import { connect } from "react-redux";
 //import \* as compActions from "../../store/actions/actions";

import DateSelector from "../../../components/DateSelector/DateSelector";
import FilmList from "../../../components/Film/FilmList/FilmList";
import SectionHeader from "../../../components/SectionHeader/SectionHeader";
import FilmEntity from "entities/FilmEntity";

class FilmsFeed extends Component {

    state = {
        films: null
    }

    componentDidMount(){
        FilmEntity.getAll(this.props.firebase, this.setFilms)
    }

    setFilms = films => {
        this.setState({
            films: films
        })
    }


    render(){
        return(
            <div className={styles.Container}>
                <SectionHeader>FILMS</SectionHeader>
                <DateSelector/>
                <FilmList datas={this.state.films} selectedDate={this.props.selectedDate}/>
            </div>
        );
    }
}

const mapStateToProps = state =>{
    return {
        session: state.firebase.session,
        firebase: state.firebase.api,
        selectedDate: state.dateSelector.selectedDate,
    }
};
 
 const mapDispatchToProps = dispatch =>{
 	return {
 		// onAction: (val) => dispatch(compActions.action(val))
 	}
 };
 
 
 export default connect(mapStateToProps, mapDispatchToProps)(FilmsFeed);
